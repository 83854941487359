<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-adicional-export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar Listado Reporte Adicionales
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="n_solicitud" class="col-md-5"
                      >N° Solicitud</label
                    >
                    <input
                      type="number"
                      id="n_solicitud"
                      v-model="filtros.n_solicitud"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="fecha_solicitud" class="col-md-5"
                      >Fecha Solicitud</label
                    >
                    <input
                      type="date"
                      id="fecha_solicitud"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.fecha_solicitud"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="sitio" class="col-md-5">Sitio</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="sitio"
                      placeholder="Sitios"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.sitios"
                      :filterable="true"
                      @input="getSelectSitio()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="transportadora_id" class="col-md-5"
                      >Empresa Transportadora</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.transportadora_id"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="transportadora_id in listasForms.empresas"
                        :key="transportadora_id.id"
                        :value="transportadora_id.id"
                      >
                        {{ transportadora_id.razon_social }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="tipo_ruta" class="col-md-5">
                      Tipo de Ruta</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_ruta"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_ruta in listasForms.tipo_rutas"
                        :key="tipo_ruta.numeracion"
                        :value="tipo_ruta.numeracion"
                      >
                        {{ tipo_ruta.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="estado" class="col-md-5">Estado</label>
                    <select
                      id="estado"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.estado"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('hidrocarburos.reportes.adicionales')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "AdicionalExport",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      sitio: {},
      filtros: {
        n_solicitud: null,
        fecha_solicitud: null,
        sitio_id: null,
        transportadora_id: null,
        tipo_ruta: null,
        estado: null,
      },
      listasForms: {
        sitio: [],
        tipo_rutas: [],
        empresas: [],
        estados: [],
      },
    };
  },

  methods: {
    getTipoRutas() {
      axios.get("/api/lista/48").then((response) => {
        this.listasForms.tipo_rutas = response.data;
      });
    },

    getEmpresas() {
      axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },
    getEstados() {
      axios.get("/api/lista/63").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    getSelectSitio() {
      this.filtros.sitio = {};
      this.filtros.sitio_id = null;
      if (this.sitio) {
        this.filtros.sitio = this.sitio;
        this.filtros.sitio_id = this.sitio.id;
      }
    },
    async getSitio() {
      await axios.get("api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/ajusteCarguesSolicitudes/adicionalExport",
        data: { filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    await this.getTipoRutas();
    await this.getSitio();
    await this.getEmpresas();
    await this.getEstados();
    this.cargando = true;
    this.cargando = false;
  },
};
</script>
